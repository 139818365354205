var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"danger","show":_vm.userData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t("common.crud.errors.fetch", { resource: _vm.$tc("modules.users.description", 1), }))+" ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t("common.crud.errors.notfound", { resource: _vm.$tc("modules.users.description", 1), }))+" "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'users' }}},[_vm._v(" "+_vm._s(_vm.$t("common.crud.errors.list", { resource: _vm.$tc("modules.users.description", 1), }))+" ")]),_vm._v(" "+_vm._s(_vm.$t("common.crud.errors.resource", { resource: _vm.$tc("modules.users.description", 2), }))+" ")],1)]),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):(_vm.userData)?_c('div',[_c('formulateForm',{attrs:{"name":"userForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
return [_c('b-card',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"text":_vm.getNameInitials,"variant":"info","size":"90px","rounded":""}})]},proxy:true}],null,true)},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.userData.name)+" ")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('formulateInput',{attrs:{"label":_vm.$t('common.crud.name'),"name":"name","placeholder":_vm.$t('common.crud.name'),"type":"text","validation":"optional|max:200,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.users.password'),"name":"password","placeholder":_vm.$t('modules.users.password'),"type":"password","validation":"optional|max:255,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('formulateInput',{attrs:{"id":"password_confirmation","label":_vm.$t('modules.users.passwordConfirmation'),"name":"password_confirmation","placeholder":_vm.$t('modules.users.passwordConfirmation'),"type":"password","validation":"optional|confirm:password"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('formulateInput',{attrs:{"label":_vm.$t('common.status'),"name":"status","placeholder":_vm.$t('common.crud.select') +
                                ' ' +
                                _vm.$t('common.status').toLowerCase(),"type":"vue-select","validation":"optional|max:32,length","options":_vm.statusOptions},on:{"update:input":function($event){_vm.formData.status = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('formulateInput',{attrs:{"label":_vm.$tc('common.email.description', 1),"name":"email","placeholder":_vm.$t('common.email.address'),"type":"text","validation":"optional|email"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$tc('modules.roles.description', 2)}},[_c('v-select',{attrs:{"id":"roles","filterable":true,"options":_vm.rolesOptions,"reduce":function (role) { return role.id; },"label":"name","placeholder":_vm.$t('common.selectOneOrMany', {
                                        resource: _vm.$tc(
                                            'modules.roles.description',
                                            2
                                        ).toLowerCase(),
                                    }),"required":"","multiple":"","closeOnSelect":false},model:{value:(_vm.formData.roles),callback:function ($$v) {_vm.$set(_vm.formData, "roles", $$v)},expression:"formData.roles"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t("common.crud.optionsNotFound"))+" ")])],2)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateErrors')],1)],1)],1),_c('b-row',{staticClass:"mt-2 ml-0"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('formulateInput',{attrs:{"disabled":hasErrors || _vm.loading,"label":_vm.loading
                                ? _vm.$t('common.crud.updating')
                                : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"}})],1)])]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
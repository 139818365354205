<template>
    <div>
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="userData === undefined">
            <h4 class="alert-heading">
                {{
                    $t("common.crud.errors.fetch", {
                        resource: $tc("modules.users.description", 1),
                    })
                }}
            </h4>
            <div class="alert-body">
                {{
                    $t("common.crud.errors.notfound", {
                        resource: $tc("modules.users.description", 1),
                    })
                }}
                <b-link class="alert-link" :to="{ name: 'users' }">
                    {{
                        $t("common.crud.errors.list", {
                            resource: $tc("modules.users.description", 1),
                        })
                    }}
                </b-link>
                {{
                    $t("common.crud.errors.resource", {
                        resource: $tc("modules.users.description", 2),
                    })
                }}
            </div>
        </b-alert>

        <div v-if="loading" class="text-center">
            <b-spinner variant="primary" class="" />
        </div>

        <div v-else-if="userData">
            <!-- User Info: Input Fields -->
            <formulateForm
                v-slot="{ hasErrors }"
                v-model="formData"
                name="userForm"
                @submit="handleSubmit"
            >
                <b-card>
                    <!-- Media -->
                    <b-media class="mb-2">
                        <template #aside>
                            <b-avatar
                                ref="previewEl"
                                :text="getNameInitials"
                                variant="info"
                                size="90px"
                                rounded
                            />
                        </template>
                        <h4 class="mb-1">
                            {{ userData.name }}
                        </h4>
                    </b-media>
                    <b-row>
                        <!-- Field: Name -->
                        <b-col cols="12" md="4">
                            <formulateInput
                                :label="$t('common.crud.name')"
                                name="name"
                                :placeholder="$t('common.crud.name')"
                                type="text"
                                validation="optional|max:200,length"
                            />
                        </b-col>

                        <!-- Field: Password -->
                        <b-col cols="12" md="4">
                            <formulateInput
                                :label="$t('modules.users.password')"
                                name="password"
                                :placeholder="$t('modules.users.password')"
                                type="password"
                                validation="optional|max:255,length"
                            />
                        </b-col>

                        <b-col cols="12" md="4">
                            <formulateInput
                                id="password_confirmation"
                                :label="
                                    $t('modules.users.passwordConfirmation')
                                "
                                name="password_confirmation"
                                :placeholder="
                                    $t('modules.users.passwordConfirmation')
                                "
                                type="password"
                                validation="optional|confirm:password"
                            />
                        </b-col>

                        <!-- Field: Status -->
                        <b-col cols="12" md="4">
                            <formulateInput
                                :label="$t('common.status')"
                                name="status"
                                :placeholder="
                                    $t('common.crud.select') +
                                    ' ' +
                                    $t('common.status').toLowerCase()
                                "
                                type="vue-select"
                                validation="optional|max:32,length"
                                :options="statusOptions"
                                @update:input="formData.status = $event"
                            />
                        </b-col>

                        <!-- Field: Email -->
                        <b-col cols="12" md="4">
                            <formulateInput
                                :label="$tc('common.email.description', 1)"
                                name="email"
                                :placeholder="$t('common.email.address')"
                                type="text"
                                validation="optional|email"
                            />
                        </b-col>

                        <!-- Field: Role -->
                        <b-col cols="12" md="4">
                            <b-form-group
                                :label="$tc('modules.roles.description', 2)"
                            >
                                <v-select
                                    id="roles"
                                    v-model="formData.roles"
                                    :filterable="true"
                                    :options="rolesOptions"
                                    :reduce="(role) => role.id"
                                    label="name"
                                    :placeholder="
                                        $t('common.selectOneOrMany', {
                                            resource: $tc(
                                                'modules.roles.description',
                                                2
                                            ).toLowerCase(),
                                        })
                                    "
                                    required
                                    multiple
                                    :closeOnSelect="false"
                                >
                                    <template slot="no-options">
                                        {{ $t("common.crud.optionsNotFound") }}
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <formulateErrors />
                        </b-col>
                    </b-row>
                </b-card>

                <b-row class="mt-2 ml-0">
                    <div class="d-flex justify-content-center">
                        <formulateInput
                            :disabled="hasErrors || loading"
                            :label="
                                loading
                                    ? $t('common.crud.updating')
                                    : $t('common.crud.update')
                            "
                            inputClass="btn btn-primary"
                            type="submit"
                        />
                    </div>
                </b-row>
            </formulateForm>
        </div>
    </div>
</template>

<script>
import {
    BAlert,
    BAvatar,
    BCard,
    BCol,
    BLink,
    BMedia,
    BRow,
    BSpinner,
    BFormGroup,
} from "bootstrap-vue"
import router from "@/router"
import Api from "@/services/api"
import vSelect from "vue-select"

import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import _ from "lodash"

export default {
    components: {
        BAlert,
        BLink,
        BSpinner,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BCard,
        vSelect,
        BFormGroup,
    },
    data() {
        return {
            formData: {
                name: "",
                status: "",
                password: "",
                password_confirmation: "",
                email: "",
                roles: "",
            },

            rolesOptions: [],
            statusOptions: [],
            updatedUserData: null,
            userData: null,
            loading: true,
        }
    },

    computed: {
        getNameInitials() {
            const split = this.userData.name.split(" ", 2)
            if (split.length < 2) {
                return this.userData.name[0] + this.userData.name[1]
            }

            const first = split[0]
            const second = split[1]

            return first.charAt(0) + second.charAt(0)
        },
    },

    mounted() {
        this.getStatus()
        this.getRoles()
        this.getUserById(router.currentRoute.params.id)
    },

    methods: {
        loadData() {
            // Setting up initial values
            this.updatedUserData = this.userData
            this.formData.name = this.userData.name
            this.formData.email = this.userData.email
            this.formData.password = this.userData.password
            this.formData.password_confirmation = this.userData.password_confirmation
            this.formData.status =
                (_.get(this.userData, "status.id") &&
                    this.userData.status.id.toString()) ||
                null
            this.formData.roles =
                (_.get(this.userData, "roles") &&
                    this.userData.roles.map(el => el.id.toString())) ||
                []
            this.loading = false
        },

        async getUserById(id) {
            const response = await Api.find("users", id, {
                include: "roles,status",

                fields: {
                    roles: "name",
                    status: "description",
                },
            })

            this.userData = response.data
            this.loadData()
        },

        async getRoles() {
            const response = await Api.findAll("roles", {
                fields: { roles: "name" },
            })

            this.rolesOptions = response.data
        },

        async getStatus() {
            const response = await Api.findAll("status", {
                fields: { status: "description" },
            })

            this.statusOptions = response.data.map(el => ({
                value: el.id,
                label: el.description,
            }))
        },

        async handleSubmit() {
            try {
                const userRequest = {
                    id: this.userData.id,
                    name: this.formData.name,
                    email: this.formData.email,
                    password: this.formData.password,
                    password_confirmation: this.formData.password_confirmation,
                    status: this.formData.status
                        ? { id: this.formData.status, type: "status" }
                        : null,
                    roles:
                        this.formData.roles.length > 0
                            ? this.formData.roles.map(el => ({
                                  id: el,
                                  type: "roles",
                              }))
                            : [],
                }

                await Api.update("users", {
                    ...userRequest,
                    include: "roles,status",
                })
                this.$toast(toastMessages.getSuccessMessage())

                this.updateUser()
            } catch (e) {
                this.$formulate.handle(e, "userForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },

        updateUser() {
            this.updatedUserData.name = this.formData.name
            this.updatedUserData.email = this.formData.email
            this.updatedUserData.password = this.formData.password
            this.updatedUserData.password_confirmation = this.formData.password_confirmation
            this.updatedUserData.status = this.statusOptions.filter(
                option => option.id === this.formData.status,
                this.formData.status
            )

            this.updatedUserData.roles = this.rolesOptions.filter(
                option => this.formData.roles.includes(option.id),
                this.formData.roles
            )
        },
    },
}
</script>
